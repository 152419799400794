import type { FunctionComponent } from 'react'
import tw from 'twin.macro'

const TagWrapper = tw.div`
  h-px-40 
  bg-accentroGray-full 
  px-px-12
  py-px-8
  text-white
  col-span-12
  w-max
  font-semibold
`
type PageTagProps = {
  text?: string
}
export const PageTag: FunctionComponent<React.PropsWithChildren<PageTagProps>> = ({ text }) => {
  return <TagWrapper>{text}</TagWrapper>
}

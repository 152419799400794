import type { SVGProps } from 'react'

export const BlackGreenFrame = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 1368 784"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M112.788 725.664H1375V784H0V-2h530.994C327.153 40.594 256.546 196.886 235.331 273.419c-21.216 76.534-122.543 452.245-122.543 452.245z"
      fill="#2B2D36"
    />
    <path
      d="M1373 725H108s35.262-121.378 60.238-211.081M538.45-2C360.625 34.068 281.312 151.736 247.967 233.328l-17.985 58.982"
      stroke="#73C49F"
      strokeWidth={props.strokeWidth ?? 7}
    />
  </svg>
)

export const BlackGreenFrameClipped = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 1368 784"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <clipPath id="cut-off-right">
        <rect x="700" y="0" width="100%" height="100%" />
      </clipPath>
    </defs>
    <path
      clipPath="url(#cut-off-right)"
      d="M112.788 725.664H1375V784H0V-2h530.994C327.153 40.594 256.546 196.886 235.331 273.419c-21.216 76.534-122.543 452.245-122.543 452.245z"
      fill="#2B2D36"
    />
    <path
      clipPath="url(#cut-off-right)"
      d="M1373 725H108s35.262-121.378 60.238-211.081M538.45-2C360.625 34.068 281.312 151.736 247.967 233.328l-17.985 58.982"
      stroke="#73C49F"
      strokeWidth={props.strokeWidth ?? 7}
    />
  </svg>
)

export const GreenBlackLineSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 360 320"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#prefix__clip0_624_42095)">
      <path
        d="M26.592 295.879H548V320H-20V-5h219.349C115.144 12.612 85.977 77.237 77.213 108.882c-8.764 31.646-50.621 186.997-50.621 186.997z"
        fill="#2B2D36"
      />
      <path
        d="M547.363 295.737H24.676s14.57-50.211 24.89-87.318M202.534-5C129.058 9.92 96.287 58.596 82.509 92.348l-7.431 24.399"
        stroke="#73C49F"
        strokeWidth={7}
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0_624_42095">
        <path fill="white" d="M0 0h360v320H0z" />
      </clipPath>
    </defs>
  </svg>
)

export const BlackGreenLineSmallClipped = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={30}
    height={30}
    viewBox="0 0 40 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#prefix__clip0_646_25130)">
      <path
        d="M-157.408 3.879H364V28h-568v-325H15.349c-84.205 17.612-113.372 82.236-122.136 113.882-8.764 31.646-50.621 186.997-50.621 186.997z"
        fill="#2B2D36"
      />
      <path d="M363.363 3.736h-522.687" stroke="#73C49F" strokeWidth={9.5} />
    </g>
    <defs>
      <clipPath id="prefix__clip0_646_25130">
        <path fill="white" d="M0 0h40v28H0z" />
      </clipPath>
    </defs>
  </svg>
)

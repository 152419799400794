import { PageLoadIndicator } from '@dreamstack/simple-components'
import { TrackingSideEffects } from '@dreamstack/tracking'
import type { FunctionComponent } from 'react'
import React from 'react'
import tw, { styled } from 'twin.macro'
import { ErrorBoundary } from '../error/ErrorBoundary'
import { IS_ACCENTRO_INVESTORS, IS_ACCENTRO_WEB, MainContainer } from '../index'
import { Footer } from './Footer'
import type { HeroProps } from './Hero'
import { Hero } from './Hero'
import { useMainLayoutContext } from './MainLayoutContext'
import type { NavbarProps } from './NavBar'
import { NavBar } from './NavBar'
import { PageTag } from './PageTag'

const IS_INTERNATIONAL = !IS_ACCENTRO_WEB && !IS_ACCENTRO_INVESTORS

const StyledDiv = styled.div<{}>(({}) => [
  (IS_ACCENTRO_WEB || IS_ACCENTRO_INVESTORS) && tw`mt-px-128 mb-px-24`,
  IS_INTERNATIONAL && tw`mt-px-80 -mb-px-24`,
])

const Layout = tw.div`
  flex
  flex-col
  // space-y-px-8
  min-h-screen
  // do not enable overflow-x-hidden since it breaks position sticky!
  // overflow-x-hidden
`

const MainBackGround = tw.div`bg-accentroWhite-full z-10 flex-1`

type MainLayoutProps = {
  navbarProps?: NavbarProps | null
  hero?: HeroProps | null
  subHero?: React.ReactNode | null
  standalone?: boolean | null
  showContactFooter?: boolean | null
  showNewsletterFooter?: boolean | null
  pageTag?: string | null
  className?: string
  removePaddingBottom?: boolean | null
}

const StyledMainContainer = styled(MainContainer)<{
  removePaddingBottom?: boolean
  className?: string
}>(({ removePaddingBottom }) => [
  tw`flex-1 mb-px-64 md:mb-px-80 lg:mb-px-96`,
  removePaddingBottom && tw`mb-0!`,
])

export const MainLayout: FunctionComponent<
  React.PropsWithChildren<MainLayoutProps>
> = ({
  children,
  navbarProps,
  hero,
  subHero,
  standalone,
  showContactFooter,
  showNewsletterFooter,
  pageTag,
  className,
  removePaddingBottom,
}) => {
  const { contactFooter, newsLetterFooter } = useMainLayoutContext()
  const showNewsletter = showNewsletterFooter === null || showNewsletterFooter
  const showContact = showContactFooter === null || showContactFooter

  return (
    <Layout className={className}>
      <PageLoadIndicator />
      <TrackingSideEffects />
      <NavBar
        standalone={standalone ?? undefined}
        {...navbarProps}
        hasHero={!!hero}
        heroSize={!!hero ? hero.size : undefined}
      />
      {hero && <Hero {...hero} />}
      {subHero}
      <MainBackGround>
        <StyledMainContainer
          removePaddingBottom={removePaddingBottom ?? undefined}
        >
          {pageTag && (
            <StyledDiv>
              <PageTag text={pageTag} />
            </StyledDiv>
          )}
          <ErrorBoundary>{children}</ErrorBoundary>
        </StyledMainContainer>
        {!standalone && (
          <>
            {showNewsletter && newsLetterFooter}
            {showContact && contactFooter}
          </>
        )}
      </MainBackGround>
      <Footer />
    </Layout>
  )
}

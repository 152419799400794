import * as Datadog from '@dreamstack/datadog'
import { useRouterLocalized, useTranslation } from '@dreamstack/i18n'
import * as React from 'react'
import tw from 'twin.macro'
import { BodyText, Button, HeadingH1 } from '../index'

const Container = tw.div`flex flex-col gap-y-px-16`
const ButtonContainer = tw.div`flex gap-x-px-16 pt-px-16`

type Props = {
  children: React.ReactNode
  bodyText?: string
}

export const ErrorBoundary = (props: Props) => {
  return (
    <Datadog.ErrorBoundary
      fallback={<ErrorBoundaryFallback bodyText={props.bodyText} />}
    >
      {props.children}
    </Datadog.ErrorBoundary>
  )
}

const ErrorBoundaryFallback = ({ bodyText }: { bodyText?: string }) => {
  const t = useTranslation()
  const router = useRouterLocalized()
  const finalBodyText = bodyText || t('accentro:error:whatNext')

  return (
    <Container>
      <HeadingH1>{t('accentro:error:title')}</HeadingH1>
      <BodyText>{finalBodyText}</BodyText>
      <ButtonContainer>
        <Button
          onClick={() => router.reload()}
          type="button"
          variant="contained"
          color="primary"
        >
          {t('accentro:error:reload')}
        </Button>
        <Button href="/" type="button" variant="outlined" color="secondary">
          {t('accentro:error:home')}
        </Button>
      </ButtonContainer>
    </Container>
  )
}

import { useAnimationOnScreen } from '@dreamstack/util'
import { motion } from 'framer-motion'
import type { FunctionComponent } from 'react'

export const FadeIn: FunctionComponent<React.PropsWithChildren<{ opacityOnly?: boolean }>> = ({
  opacityOnly,
  children,
}) => {
  const { controls, ref } = useAnimationOnScreen()
  const variants = opacityOnly
    ? { visible: { opacity: 1 }, hidden: { opacity: 0 } }
    : {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 },
      }
  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="visible"
      transition={{ duration: 0.5 }}
      variants={variants}
    >
      {children}
    </motion.div>
  )
}

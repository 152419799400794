import { useTranslation } from '@dreamstack/i18n'
import map from 'lodash/map'
import Image from 'next/legacy/image'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import 'twin.macro'
import tw, { css, styled } from 'twin.macro'
import { Text_16_24 } from './Texts'
import { useNavBarEntries } from './useNavBarEntries'

const Container = tw.div`
bg-accentroGray-full
text-accentroWhite-full
  z-10
  px-4  
  md:px-12
  py-16
  md:py-8
  flex
  flex-col
  md:flex-row
  items-center
  justify-center
  // mt-4
`

const CompanyLabel = tw.div`
  text-xl font-extrabold tracking-wide
`

const Spacer = tw.div`flex-1`

const LinksWrapper = tw.div`
  flex
  flex-row
  flex-wrap
  -mx-3
  -my-1
  md:-m-4
  items-center
  justify-center
  pt-6
  md:pt-0
`

const activeStyle = css`
  border-color: initial;
`
const linkHoverStyles = css`
  ${tw`cursor-pointer`}
  border-bottom: 2px solid transparent;
  &:hover {
    ${activeStyle}
  }
`

const StyledLink = styled.a(() => [tw`md:m-4 mx-3 my-1`, linkHoverStyles])

export const Footer: FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  const entries = useNavBarEntries('Footer')
  const t = useTranslation()
  return (
    <>
      <Container>
        <CompanyLabel>
          <Image
            src={'/static/images/accentro-logo-white.png'}
            width={175}
            height={20}
            alt={'Accentro Logo image'}
          />
        </CompanyLabel>
        <Spacer />
        <LinksWrapper>
          <StyledLink
            href="#"
            onClick={(e) => {
              e.preventDefault()

              /* @ts-ignore */
              UC_UI.showSecondLayer()
            }}
          >
            <Text_16_24 tw="font-semibold">
              {t('accentro:footer.cookies')}
            </Text_16_24>
          </StyledLink>
          {map(entries, ({ label, staticPage }) => {
            const slug = staticPage?.slug
            return (
              <Link key={slug} href={`/${slug}`} passHref legacyBehavior>
                <StyledLink>
                  <Text_16_24 tw="font-semibold">{label}</Text_16_24>
                </StyledLink>
              </Link>
            )
          })}
        </LinksWrapper>
      </Container>
    </>
  )
}

import type { StaticPageHeroImageFragment } from '@dreamstack/investors-graphql'
import Image from 'next/legacy/image'
import type { FunctionComponent } from 'react'
import tw, { styled } from 'twin.macro'
import { FadeIn } from './FadeIn'

import {
  TransparentGradientLeftToRight,
  TransparentGradientTopToBottom,
} from './Gradients'
import {
  BlackGreenFrame,
  BlackGreenFrameClipped,
  BlackGreenLineSmallClipped,
  GreenBlackLineSmall,
} from './HeroSvgs'
import { GridWithGaps, MainContainer } from './MainGrid'
import { H1Hero, HeadingH4 } from './Texts'

export type HeroSize =
  | 'large'
  | 'medium'
  | 'small'
  | 'international'
  | 'campaign'

const HeroImageContainer = styled.div(({ size }: { size?: HeroSize }) =>
  size === 'campaign'
    ? [
        tw`w-full h-full md:flex[8] lg:(flex[12]) relative`,
        `@media (min-width: 2200px) {
    max-width: calc(100% * 0.83);
  }`,
        `z-index: -1;`,
      ]
    : [tw`w-full h-full absolute overflow-hidden`, `z-index: -1;`]
)

const HeroTextContainer = styled.div(
  ({ forceDarkBg }: { forceDarkBg: boolean }) => [
    tw`h-full pt-px-96`,
    forceDarkBg && tw` text-white`,
  ]
)

const HeroTitleInternational = styled.div(({}: {}) => [
  tw`text-px-28 leading-px-42 md:(text-px-56 leading-px-70) font-semibold`,
])

const HeroTextPlacement = styled.div(
  ({ textCentered, size }: { textCentered: boolean; size?: HeroSize }) => [
    size !== 'campaign' && tw`justify-center`,
    size === 'campaign' &&
      tw`justify-start pt-px-20 md:(col-end-8 padding-top[90px]) ml:padding-top[224px]`,
    tw`flex flex-col h-full break-words overflow-clip`,
    textCentered &&
      tw`col-start-1 col-end-12 text-center md:(col-start-3 col-end-11)`,
    !textCentered && tw`col-start-1 col-span-12`,
  ]
)
const StyledHeroContainer = styled.div(
  ({
    size,
    forceDarkBg,
    noMarginBottom,
  }: {
    size?: HeroSize
    forceDarkBg: boolean
    noMarginBottom: boolean
  }) => [
    // keep the Hero-Size in sync with useResponsiveHeroVariants
    // navbar heights
    //h-px-96 md:(h-px-128) lg:(h-px-196)
    tw`w-full fixed flex-shrink-0`,
    size === 'large' && tw`h-px-480 md:h-px-576 lg:h-px-1080`,
    size === 'medium' && tw`h-px-320 md:h-px-576 lg:h-px-860`,
    size === 'small' && tw`h-px-320 md:h-px-480 lg:h-px-480`,
    size === 'international' && tw`h-px-480 text-center md:height[798px]`,
    size === 'campaign' &&
      tw`bg-accentroGray-full h-px-320 md:height[503px] ml:height[798px]`,
    noMarginBottom ? `` : tw`mb-px-48 lg:mb-px-64`,
    forceDarkBg && tw`bg-accentroGray-full`,
  ]
)

const BlackFrameSpaceBlocker = styled.div(() => [
  [tw`h-full w-full relative flex-1 hidden lg:block`],
])

export type HeroContainerProps = StaticPageHeroImageFragment['hero'] & {
  size?: HeroSize
  forceDarkBg?: boolean
  noMarginBottom?: boolean
}

export const HeroContainer: FunctionComponent<
  React.PropsWithChildren<HeroContainerProps>
> = ({
  backgroundImage,
  title,
  subtitle,
  size,
  forceDarkBg = true,
  noMarginBottom = false,
}) => {
  const textCentered = size === 'large'
  const gradient =
    backgroundImage &&
    (size === 'large' ? (
      <TransparentGradientTopToBottom />
    ) : (
      <TransparentGradientLeftToRight />
    ))
  const backgroundIsVideo = !!backgroundImage?.contentType?.startsWith('video')
  return (
    <>
      <StyledHeroContainer
        noMarginBottom={noMarginBottom}
        size={size}
        forceDarkBg={forceDarkBg && !backgroundImage}
      >
        {!!backgroundImage && !!backgroundImage.url && (
          <div tw="absolute inset-0 flex">
            {size === 'campaign' && <BlackFrameSpaceBlocker />}
            <HeroImageContainer size={size}>
              {backgroundIsVideo ? (
                <video
                  loop
                  autoPlay
                  playsInline
                  muted
                  width="100%"
                  height="100%"
                  style={{ objectFit: 'cover', height: '100%' }}
                >
                  <source
                    src={backgroundImage.url}
                    type={backgroundImage.contentType ?? undefined}
                  />
                </video>
              ) : (
                <Image
                  objectFit="cover"
                  layout="fill"
                  src={backgroundImage.url}
                  title={title || undefined}
                  alt={backgroundImage.description ?? ''}
                  // quality="100"
                  priority={true}
                />
              )}

              {gradient}
              {size === 'campaign' && (
                <>
                  <div tw="absolute inset-0 h-full w-full z-10">
                    <BlackGreenFrame
                      preserveAspectRatio="xMinYMid meet"
                      tw="hidden sm:block w-full h-auto col-span-full max-height[320px] md:max-height[503px] ml:(max-height[798px])"
                    />
                    <GreenBlackLineSmall
                      preserveAspectRatio="xMinYMid meet"
                      tw="sm:hidden w-full h-auto col-span-full max-height[320px] md:max-height[503px] ml:(max-height[798px])"
                    />
                  </div>
                  <div tw="absolute inset-0 h-full w-full">
                    <BlackGreenFrameClipped
                      preserveAspectRatio="none"
                      tw="hidden sm:block w-full h-auto absolute max-height[320px] md:max-height[503px] ml:(max-height[798px])"
                    />
                    <BlackGreenLineSmallClipped
                      preserveAspectRatio="none"
                      tw="sm:hidden w-full absolute bottom-0 max-height[320px] md:max-height[503px] ml:(max-height[798px])"
                    />
                  </div>
                </>
              )}
            </HeroImageContainer>
          </div>
        )}
        <HeroTextContainer forceDarkBg={forceDarkBg}>
          <MainContainer tw="h-full">
            <GridWithGaps tw="h-full">
              <HeroTextPlacement size={size} textCentered={textCentered}>
                <FadeIn>
                  {size === 'international' && (
                    <div tw="mt-px-24 md:(margin-top[224px]) lg:(margin-top[304px])">
                      <HeroTitleInternational
                        dangerouslySetInnerHTML={{ __html: title ?? '' }}
                      />
                      {subtitle && (
                        <div tw="mt-px-16 md:(mt-px-24) font-semibold text-px-20 leading-px-30 md:(text-px-24 leading-px-36) lg:(font-size[28px] line-height[42px])">
                          {subtitle}
                        </div>
                      )}
                    </div>
                  )}
                  {size !== 'international' && (
                    <div>
                      <H1Hero
                        dangerouslySetInnerHTML={{ __html: title ?? '' }}
                      />
                      {subtitle && <HeadingH4>{subtitle}</HeadingH4>}
                    </div>
                  )}
                </FadeIn>
              </HeroTextPlacement>
            </GridWithGaps>
          </MainContainer>
        </HeroTextContainer>
      </StyledHeroContainer>
    </>
  )
}

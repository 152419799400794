import type { StaticPageHeroImageFragment } from '@dreamstack/investors-graphql'
import type { FunctionComponent } from 'react'
import tw, { styled } from 'twin.macro'
import type { HeroSize } from './HeroContainer';
import { HeroContainer } from './HeroContainer'

const TransparentSpaceBlocker = styled.div(
  ({ size, noMarginBottom }: { size?: HeroSize; noMarginBottom: boolean }) => [
    tw`w-full flex-shrink-0 bg-transparent -mt-px-128 sm:(margin-top[-132px]) md:(margin-top[-150px]) lg:(-mt-px-196)`, // Keep in snyc with NavbarSpaceBlocker in NavBar.tsx
    size === 'large' && tw`h-px-480 md:h-px-576 lg:h-px-1080`,
    size === 'medium' && tw`h-px-320 md:h-px-576 lg:h-px-860`,
    size === 'small' && tw`h-px-320 md:h-px-480 lg:h-px-480`,
    size === 'international' && tw`h-px-480 text-center md:height[798px]`,
    size === 'campaign' && tw`h-px-320 md:height[503px] ml:height[798px]`,
    noMarginBottom ? `` : tw`mb-px-64 md:mb-px-80 lg:mb-px-96`,
  ]
)

export type HeroProps = StaticPageHeroImageFragment['hero'] & {
  size?: HeroSize
  forceDarkBg?: boolean
  noMarginBottom?: boolean
}

export const Hero: FunctionComponent<React.PropsWithChildren<HeroProps>> = ({
  backgroundImage,
  title,
  subtitle,
  size,
  forceDarkBg = true,
  noMarginBottom = false,
}) => {
  return (
    <>
      <TransparentSpaceBlocker size={size} noMarginBottom={noMarginBottom} />
      <HeroContainer
        backgroundImage={backgroundImage}
        title={title}
        subtitle={subtitle}
        forceDarkBg={forceDarkBg}
        size={size}
        noMarginBottom={noMarginBottom}
      />
    </>
  )
}

import type { FunctionComponent } from 'react'
import 'twin.macro'

export const TransparentGradientBottomToTop: FunctionComponent<React.PropsWithChildren<{}>> = () => {
  return (
    <div
      tw="absolute inset-0"
      style={{
        background: 'linear-gradient(to top, #00000088 0%, #00000000 100%)',
      }}
    />
  )
}

export const TransparentGradientTopToBottom: FunctionComponent<React.PropsWithChildren<{}>> = () => {
  return (
    <div
      tw="absolute inset-0"
      style={{
        background: 'linear-gradient(to bottom, #00000080 0%, #00000000 100%)',
      }}
    />
  )
}

export const TransparentGradientLeftToRight: FunctionComponent<React.PropsWithChildren<{}>> = () => {
  return (
    <div
      tw="absolute inset-0"
      style={{
        background: 'linear-gradient(to right , #00000080 33%, #00000000 100%)',
      }}
    />
  )
}

export const ColoredGradient: FunctionComponent<React.PropsWithChildren<{}>> = () => {
  return (
    <div
      tw="absolute inset-0"
      style={{
        background: 'linear-gradient(90deg, #3B5F6B 0%, #4E7D8D 100%)',
      }}
    />
  )
}

import { useScrollDirection } from '@dreamstack/feature-components/src/hooks/useScrollDirection'
import { useOnScreen } from '@dreamstack/util'
import { useAnimation } from 'framer-motion'
import { useEffect, useRef } from 'react'

export const useAnimationOnScreen = () => {
  const controls = useAnimation()
  // TODO: Fix typing. Could be a lot of different html elements here.
  const ref = useRef<any>()
  const isOnScreen = useOnScreen(ref)
  const { scrollDirection } = useScrollDirection()
  useEffect(() => {
    if (isOnScreen || scrollDirection === 'UP') {
      controls.start('visible')
    } else if (!isOnScreen && !(scrollDirection === 'DOWN')) {
      controls.start('hidden')
    }
  }, [controls, isOnScreen, scrollDirection])

  return {
    controls,
    ref,
  }
}
